import React from "react"
import Layout from "../../layouts/index"

export default () => (
  <Layout>
    <div className="pd-hero AIOps">
      <div className="content-wrap">
        <div className="pd-hero-wrap">
          <div className="pd-top-info AIOps">
            <div className="pd-name">
              <div className="pd-logo"></div>
              <div className="title">
                <span>Luma</span>
                <h2>智能运维</h2>
              </div>
            </div>
            <h4>利用AI改善自动化运维</h4>
            <p>
              <span>AIOps (Artificial Intelligence for IT Operations)</span>
              ，智能化运维，将人工智能应用于运维领域，基于已有的运维大数据（日志、指标、监控信息、应用信息等），通过机器学习的方式来进一步解决自动化运维没办法解决的问题。
            </p>
            <p>
              AIOps在自动化运维的基础上，增加了一个
              <span>基于机器学习的大脑</span>
              ，指挥监测系统采集大脑决策所需的数据，做出分析、决策，并指挥自动化脚本去执行大脑的决策，从而达到运维系统的整体目标（噪声消除、根源分智能预警等）。
            </p>
          </div>
          <div className="pd-hero-AIOps-bg"></div>
        </div>
      </div>
    </div>
    <div className="pd-section AIOps-fixs">
      <h3>AIOps可解决的问题</h3>
      <div className="pd-AIOps-cards">
        <div className="pd-AIOps-card">
          <div className="top-icon"></div>
          <h4>噪声消除</h4>
          <p>
            动态阈值连续设置报警标准，AIOps并没有像cpu>90%那样折裹，而是着眼于周期性并使用数十种衡量指标来计算每一时刻的最佳阈值
          </p>
        </div>
        <div className="pd-AIOps-card">
          <div className="top-icon"></div>
          <h4>智能预警</h4>
          <p>
            对导致事故的所有受影响资产和情况的上下文理解，使系统能够正确识别和组建响应团队，并清除地解释情况
          </p>
        </div>
        <div className="pd-AIOps-card">
          <div className="top-icon"></div>
          <h4>异常检测</h4>
          <p>
            机器学习通过了解趋势驱动的模式来执行多变量异常检测，足够精准，知道什么时候不期望发生什么
          </p>
        </div>
        <div className="pd-AIOps-card">
          <div className="top-icon"></div>
          <h4>根因分析</h4>
          <p>
            一种因果模型的有监督学习，通过该模型，使用时间戳和拓补信息将时间关联链接起来，从而实现精准的根本原因分析
          </p>
        </div>
        <div className="pd-AIOps-card">
          <div className="top-icon"></div>
          <h4>故障预测</h4>
          <p>
            不断学习正常和异常的行为，精确地预测性能趋势和故障点，可以及时预测故障，从而避免这些问题的发生
          </p>
        </div>
        <div className="pd-AIOps-card">
          <div className="top-icon"></div>
          <h4>智能修复</h4>
          <p>
            因为系统精确的降噪和根因分析，使得系统可以准确的知道发生了什么问题，从而通过自动化的手段进行自我修复，实现系统的自愈
          </p>
        </div>
      </div>
    </div>
    <div className="pd-section">
      <div className="content-wrap">
        <h3>功能架构</h3>
        <div className="pd-AIOps-struc">
          <div className="pd-AIOps-struc-col">
            <div className="AIOps-struc-card">
              <div className="title">通用服务</div>
              <p>用户管理</p>
              <p>认证授权</p>
              <p>管理配置</p>
              <p>统计管理</p>
              <p>日志管理</p>
              <p>存储管理</p>
            </div>
          </div>
          <div className="pd-AIOps-struc-col">
            <div className="pd-AIOps-struc-row">
              <div className="AIOps-struc-card">
                <div className="title">数据可视化</div>
                <p>仪表板</p>
              </div>
              <div className="AIOps-struc-card">
                <div className="title">数据可视化</div>
                <p>模式标记</p>
                <p>过滤反馈</p>
              </div>
            </div>
            <div className="AIOps-struc-card purple">
              <div className="title">数据处理</div>
              <div className="content-grid">
                <div className="content-block">
                  <h4>数据预处理模块</h4>
                  <ul>
                    <li>事件丰富</li>
                    <li>日志分析</li>
                    <li>数据序列化</li>
                    <li>特征提取</li>
                  </ul>
                </div>
                <div className="content-block">
                  <h4>事件处理模块</h4>
                  <ul>
                    <li>操作引擎</li>
                    <li>通知模块</li>
                  </ul>
                </div>
                <div className="content-block">
                  <h4>机器学习引擎</h4>
                  <ul>
                    <li>模式识别</li>
                    <li>根因分析</li>
                    <li>故障预测</li>
                    <li>智能预警</li>
                    <li>模型学习</li>
                  </ul>
                </div>
                <div className="content-block">
                  <h4>模型训练模块</h4>
                  <ul>
                    <li>模型库</li>
                    <li>模型部署</li>
                    <li>A/B测试</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="AIOps-struc-card mid-last">
              <div className="title">数据接入</div>
              <p>告警数据</p>
              <p>日志采集</p>
              <p>心跳监听</p>
            </div>
          </div>
          <div className="pd-AIOps-struc-col">
            <div className="AIOps-struc-card">
              <div className="title">集成服务：接口</div>
              <p>外部系统接口</p>
            </div>
            <div className="AIOps-struc-card purple">
              <div className="title">外部系统</div>
              <p>告警系统</p>
              <p>IT SM系统</p>
              <p>邮件系统</p>
            </div>
            <div className="AIOps-struc-arrow"></div>
          </div>
        </div>
      </div>
    </div>
    <div className="pd-section AIOps-core-func">
      <h3>核心功能表</h3>
      <ul>
        <li>
          <p>APM工具连接器</p>
        </li>
        <li>
          <p>数据流管理</p>
        </li>
        <li>
          <p>日志(Log)数据提取</p>
        </li>
        <li>
          <p>指标(Metrics)数据提取</p>
        </li>
        <li>
          <p>CMDB丰富</p>
        </li>
        <li>
          <p>行为发现 (Behavior Discovery)</p>
        </li>
        <li>
          <p>噪声抑制 (Noise Suppression)</p>
        </li>
        <li>
          <p>问题发现与预测 (Issue Discovery and Prediction)</p>
        </li>
        <li>
          <p>异常检测 (Anomaly Detection)</p>
        </li>
        <li>
          <p>根因确定 (Root Cause Determination)</p>
        </li>
        <li>
          <p>仪表板 (Dashboard)</p>
        </li>
        <li>
          <p>自动工单创建</p>
        </li>
        <li>
          <p>A/B模型测试和生产管理</p>
        </li>
        <li>
          <p>历史数据管理</p>
        </li>
        <li>
          <p>自主修复/操作 (Autonomous Remediation / Operations)</p>
        </li>
        <li>
          <p>
            公民数据科学家（Citizen Data Scientists）
            <br />
            ML有监督训练(Supervised Training)
          </p>
        </li>
      </ul>
    </div>
    <div className="pd-section">
      <div className="content-wrap">
        <h3>业务价值</h3>
        <div className="AIOps-values">
          <div className="AIOps-value">
            <h5>提升运维效率</h5>
            <p>
              人工智能，机器学习，事件流处理和自动化决策提升IT运维效率，减少90%+的无效告警(噪声)，随着系统自我学习效率将会更高。
            </p>
          </div>
          <div className="AIOps-value">
            <h5>加快修复速度</h5>
            <p>
              AIOps软件可以监控多个系统，服务和资源之间的因果关系，对不同的数据源进行聚类和关联。这些分析和机器学习功能使软件能够执行功能强大的根本原因分析，从而加快对困难和异常问题的故障排除和修复的速度。
            </p>
          </div>
          <div className="AIOps-value">
            <h5>改善用户体验</h5>
            <p>
              实时的人工智能分析设备和应用程序告警数据流，学习、抑制、识别根源告警和智能修复，提升所运维系统的可用性带来积极的最终用户体验。
            </p>
          </div>
          <div className="AIOps-value">
            <h5>减少人力成本</h5>
            <p>
              AIOps使用机器学习来检测系统行为的变化，不断适应环境，且学习行为不需要手动设置阈值和维护规则，大大降低人力维护成本。
            </p>
          </div>
          <div className="AIOps-value">
            <h5>简化日常流程</h5>
            <p>
              日常任务的自动化，使IT部门能够专注于严重的复杂问题，集中精力改进基础设施和流程，而不是处理重复和耗时的任务。
            </p>
          </div>
          <div className="AIOps-value">
            <h5>协调团队合作</h5>
            <p>
              AIOps可以改善IT组之间以及IT与其他业务部门之间的协作和工作流(work
              flow)活动。
              借助量身定制的报告和仪表板，团队可以快速了解他们的任务和要求，并与他人进行交互，而无需了解其他团队需要知道的一切。
            </p>
          </div>
        </div>
      </div>
    </div>
    <div className="pd-section AIOps-enterp">
      <div className="content-wrap">
        <h3>企业视图</h3>
        <div className="AIOps-enterp-bg"></div>
      </div>
    </div>
    <div className="pd-section">
      <div className="content-wrap">
        <h3>Luma提供了一种独特的方式将AI实施到企业IT中</h3>
        <h5>
          IT团队能够利用AIOps的功能，通过ChatOps无约束的会话通知和工单管理(ITSM)，以及内置的自动化功能：
        </h5>
        <div className="AIOps-auto-funcs">
          <ul>
            <li>大大减少警觉疲劳</li>
            <li>减少平均解决时间（MTTR）</li>
            <li>防止跨IT栈出现问题</li>
            <li>简化操作</li>
            <li>经济有效地满足数字化转型的需求</li>
          </ul>
        </div>
      </div>
    </div>
  </Layout>
)
